import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  branches: [
    {
      name: `Branch 1`,
      description: '',
    },
    {
      name: `Branch 2`,
      description: '',
    },
    {
      name: `Branch 3`,
      description: '',
    },
  ],
};

const slice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    returnToDefaultValues(state, { payload }) {
      return initialState;
    },
    setChartData(state, { payload }) {
      return payload;
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
