import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
  }

  div:hover::-webkit-scrollbar-thumb,
  section:hover::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.colors.blue100};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.colors.gray200};
    border-radius: 18px;
  }

  p {
    margin-bottom: 0px;
  }

  .dndflow {
    flex: 1;
  }

  /* set z-index to -1 for the iframe blocking the root div */
  body>#root~script:last-of-type+iframe {
    z-index: -1 !important;
  }

  .MuiAutocomplete-popper .MuiAutocomplete-noOptions,
  .MuiAutocomplete-popper .MuiAutocomplete-groupLabel {
    color: ${({ theme }) => theme.palette.colors.black100};
  }

  .rbc-day-slot .rbc-events-container {
    margin-right: 0;
  }

  .MuiButton-root {
    text-transform: inherit !important;
  }

  .react-flow__node {
    z-index: -1 !important;
  }

  @keyframes glow {
    0% { box-shadow: 0 0 0 0 ${({ theme }) => theme.palette.colors.blue100}; }
    50% { box-shadow: 0 0 20px 0 ${({ theme }) =>
      theme.palette.colors.blue100}; }
  }

  @keyframes glow-small {
    0% { box-shadow: 0 0 0 0 ${({ theme }) => theme.palette.colors.blue100}; }
    50% { box-shadow: 0 0 8px 0 ${({ theme }) =>
      theme.palette.colors.blue100}; }
  }

  .react-calendar {
    background: white;
    border: none;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    padding-right: 12px;
    margin-right: 12px;
    border-right: 1px solid #eee;
  }
  .react-calendar__navigation__label__labelText {
    font-weight: 500;
    font-size: 15px;
  }
  .react-calendar__century-view__decades,
  .react-calendar__decade-view__years,
  .react-calendar__year-view__months {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 22px;
    grid-column-gap: 11px;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    align-items: center !important;
    background-color: transparent;
    border: none;
    padding-bottom: 20%;
    position: relative;
  }
  .react-calendar__navigation button {
    background: none;
  }
  .react-calendar__navigation__label:hover {
    background-color: transparent;
  }
  .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button {
    padding: 5px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #eee !important;
    font-size: 1.3em;
  }
  .react-calendar:last-child {
    border-right: none;
  }
  .react-calendar__month-view__days {
    display: grid !important;
    justify-items: center;
    grid-column-gap: 0px;
    grid-template-columns: repeat(7, 1fr);
    grid-row-gap: 15px;
  }

  .react-calendar__month-view__weekdays {
    display: grid !important;
    justify-items: center !important;
    grid-template-columns: repeat(7, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 0px;
    margin-bottom: 20px;
    width: 100%;
  }
  .react-calendar__month-view__weekdays__weekday {
    width: 100% !important;
    justify-items: center;
    max-width: max-content;
    overflow: visible !important;
  }
  .react-calendar__month-view__days__day {
    width: 100% !important;
  }

  .react-calendar__navigation__label {
    position: relative;
  }
  .react-calendar__navigation__label::after {
    content: '';
    border: solid black;
    border-width: 0 1px 1px 0;
    padding: 3px;
    position: absolute;
    top: 25%;
    right: 10%;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.75em;
  }
  .react-calendar__tile,
  .react-calendar__month-view__days__day {
    font-weight: 600;
    background-color: white;
    padding: 1px;
    width: 35px;
    height: 35px;
  }
  .react-calendar__month-view__days__day--weekend {
    color: black;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #eee;
  }
  .react-calendar__tile {
    width: 100% !important;
    max-width: 100% !important;
    text-align: center;
    border-radius: 100%;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: ${({ theme }) => theme.palette.colors.gray300};
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    color: white;
    background-color: #e6e6e6;
  }
  .react-calendar__tile--hasActive {
    background: ${({ theme }) => theme.palette.colors.blue100} !important;
    color: white;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: ${({ theme }) => theme.palette.colors.blue100};
  }
  .react-calendar__tile--active {
    background: ${({ theme }) => theme.palette.colors.blue100};
    color: white;
  }
  .react-calendar__year-view__months > .react-calendar__tile--active {
    background: ${({ theme }) => theme.palette.colors.blue100};
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: ${({ theme }) => theme.palette.colors.blue100};
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation > .react-calendar__navigation__label:disabled {
    background-color: transparent;
  }
  /*.react-calendar__tile >.react-calendar__tile--now > .react-calendar__tile--active {*/
  /*    background-color: red;*/
  /*}*/

  .react-calendar__month-view__days .react-calendar__tile {
    max-width: 35px !important;
  }
`;

export default GlobalStyles;
